@import-normalize;
@import "~@fortawesome/fontawesome-free/css/all.min.css";

html {
  width: 100%;
  height: 100%;
  font-size: 12px;
  line-height: 1.2;

  @media only screen and (min-width: 321px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 411px) {
    font-size: 16px;
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  width: 100%;
  height: 100%;
  margin: env(safe-area-inset-top, 0) 0 env(safe-area-inset-bottom, 0) 0;
  color: var(--text-color);
  background-color: var(--body-color);
  -webkit-text-size-adjust: 100%;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

#root {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  background-color: var(--background-color);
  @media only screen and (min-width: 768px) {
    flex-direction: column;
  }
}

.carousel {
  width: 100%;
  text-align: center;
  padding-bottom: 0.5rem;

  & > div {
    margin-bottom: 0.5rem;
  }
}

.not-mobile {
  @media only screen and (max-width: 767px) {
    display: none !important;
  }
}

.not-tablet {
  @media only screen and (min-width: 768px) and (max-width: 1023px) {
    display: none !important;
  }
}

.not-desktop {
  @media only screen and (min-width: 1024px) {
    display: none !important;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
