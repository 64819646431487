.wrapper {
  display: flex;
  flex-direction: column;

  &.nested {
    margin-bottom: 1.5rem;

    .container {
      margin-bottom: 0.5rem;
    }
  }

  .container {
    position: relative;
    display: flex;

    .labelContainer {
      position: absolute;
      top: calc(50% - 0.75rem);
      left: 1rem;
      font-size: 1.2rem;
      pointer-events: none;
      transition: top 200ms cubic-bezier(0, 0, 0.2, 1);

      &.focused,
      &.filled {
        top: 0.5rem;
        font-size: 0.8rem;

        label {
          font-size: 0.8rem;
        }
      }
    }

    .childrenContainer,
    .input {
      flex: 1;
      padding: 2rem 1rem 1rem;
      line-height: 1.5rem;
      color: var(--on-surface-color);
      background-color: var(--surface-color);
      border-radius: 2px;
      border-bottom: 1px solid var(--primary-color);
    }

    .childrenContainer {
      display: flex;
      flex-direction: column;

      & > * {
        margin-bottom: 0.5rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      &.error {
        border-bottom: 2px solid var(--error-color);
      }

      input {
        margin-right: 0.5rem;
      }
    }

    .input {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px; // Must be at least 16px to not zoom on iOS
      border: none;
      outline: none;

      &:focus {
        + .underline {
          visibility: visible;
          width: 100%;
        }
      }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      -webkit-text-fill-color: var(--on-surface-color);
      -webkit-box-shadow: 0 0 0px 1000px var(--surface-color) inset;
      transition: background-color 5000s ease-in-out 0s;
    }

    .currencySymbol {
      position: absolute;
      left: 1rem;
      top: 2.2rem;
    }

    .currencyInput {
      padding-left: 2rem;
    }

    .inputIcon {
      position: absolute;
      right: 1rem;
      top: 2rem;
      font-size: 4px;
      color: var(--on-surface-color);

      i {
        font-size: 1rem;
      }
    }

    .underline {
      position: absolute;
      width: 0;
      height: 2px;
      bottom: 0;
      left: 0;
      background-color: var(--primary-color);
      visibility: none;
      transition: all 0.5s linear;
    }

    &.branded {
      .childrenContainer,
      .input {
        border-bottom: 1px solid var(--primary-color-branded);
      }

      .underline {
        background-color: var(--primary-color-branded);
      }
    }

    &.disabled {
      .childrenContainer,
      .input {
        opacity: 0.6;
      }
    }

    &.readonly {
      pointer-events: none;
    }

    &.error {
      .underline {
        background-color: var(--error-color);
        width: 100%;
      }
    }
  }

  .errorsContainer {
    padding: 0 0.5rem;
    font-size: 0.875rem;
    color: var(--error-color);
  }
}
