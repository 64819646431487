/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid var(--outline-color);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid var(--outline-color);
}

.accordion__button {
  background-color: var(--background-color);
  cursor: pointer;
  padding: 1rem;
  text-align: left;
  border: none;
  outline: none;
  display: flex;
  justify-content: space-between;
}

// .accordion__button:hover {
//   background-color: #ddd;
// }

.accordion__button:after {
  display: inline-block;
  content: "";
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded="true"]::after,
.accordion__button[aria-selected="true"]::after {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 1rem 2rem;
  background-color: var(--surface-color);
  // animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

// @keyframes fadein {
//   0% {
//     opacity: 0;
//   }

//   100% {
//     opacity: 1;
//   }
// }
