.animated {
  text-decoration: none;

  label {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 0;
      height: 1px;
      bottom: 0;
      left: 0;
      visibility: hidden;
      transition: all 0.3s ease-in-out;
    }
  }

  &:hover {
    label {
      &:before {
        visibility: visible;
        width: 100%;
      }
    }
  }

  &.reverseAnimation {
    label {
      &:before {
        left: unset;
        right: 0;
        visibility: visible;
        width: 100%;
      }
    }

    &:hover {
      label {
        &:before {
          visibility: visible;
          width: 0%;
        }
      }
    }
  }
}
