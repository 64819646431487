.footer {
  width: 100%;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width: 568px) {
    flex-direction: row;
  }

  @media only screen and (min-width: 768px) {
    padding: 1rem 2rem;
    display: flex;
  }

  .links {
    display: flex;
    flex-direction: column;
    align-items: center;

    .separator {
      display: none;
    }

    @media only screen and (min-width: 321px) {
      flex-direction: row;

      .separator {
        display: unset;
      }
    }
  }
}
