.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 1rem;
  background: linear-gradient(135deg, var(--overlay-color) 25%, transparent 25%) -50px
      0,
    linear-gradient(225deg, var(--overlay-color) 25%, transparent 25%) -50px 0,
    linear-gradient(315deg, var(--overlay-color) 25%, transparent 25%),
    linear-gradient(45deg, var(--overlay-color) 25%, transparent 25%);
  background-size: 100px 100px;
  background-color: lighten(#000, 2%);

  @media only screen and (min-width: 768px) {
    align-items: center;
  }

  .wrapper {
    display: flex;
    flex-direction: column;

    .time {
      font-size: 3rem;
      margin-bottom: 0.5rem;
    }
  }
}
