.confirmBackdrop {
  opacity: 0.8;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  width: 100vw;
  height: 100vh;
  background-color: var(--overlay-color);
}

.container {
  position: fixed;
  top: env(safe-area-inset-top, 0);
  left: env(safe-area-inset-left, 0);
  right: env(safe-area-inset-right, 0);
  bottom: env(safe-area-inset-bottom, 0);
  z-index: 10;
  overflow: hidden;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;

  .dialog {
    position: relative;
    pointer-events: none;

    .content {
      position: relative;
      display: flex;
      flex-direction: column;
      max-width: 100vw;
      width: 600px;
      pointer-events: auto;
      background-color: var(--background-color);
      background-clip: padding-box;
      border: 1px solid var(--outline-color);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);

      outline: 0;

      &.large {
        width: 900px;
      }

      &.small {
        width: 300px;
      }

      .header {
        flex: 0 0 50px;
        width: 100%;
        height: 50px;
        background-color: var(--surface-color);
        border-bottom: 1px solid var(--outline-color);
        display: flex;

        .title {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 1rem;
          box-sizing: border-box;
        }

        .close {
          flex: 0 0 50px;

          button {
            width: 50px;
            height: 50px;
            border-top: none;
            border-bottom: none;
            border-right: none;
          }
        }
      }
      .body {
        flex: 1;
        box-sizing: border-box;
        max-height: 70vh;
        overflow-x: hidden;
        overflow-y: auto;

        &.hasPadding {
          padding: 1rem 2rem;
        }
      }

      .actions {
        flex: 0 0 50px;
        display: flex;

        & > button {
          flex: 1;
          justify-content: center;
          border-left: none;
          border-right: none;
          border-bottom: none;
        }

        & > .uploadFile {
          position: relative;
          flex: 1;
          transition: all 0.3s ease-in-out;
          border-top: 1px solid transparent;

          &:hover {
            border-top: 1px solid var(--on-primary-color-branded);
          }

          & > span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            pointer-events: none;
            background-color: var(--primary-color-branded);
            color: var(--on-primary-color-branded);
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            text-transform: uppercase;
            font-weight: 400;
          }

          & > input {
            color: transparent;
            cursor: pointer;
            outline: none;
            border: none;
            box-sizing: content-box;
            width: 100%;
            height: 100%;

            &::-webkit-file-upload-button {
              visibility: hidden;
            }
          }
        }
      }
    }
  }
}
