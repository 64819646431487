.container {
  background-color: var(--surface-color);
  box-sizing: border-box;
  padding: 4px 4px 0;

  .wrapper {
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: var(--text-color);
    border-radius: 4px;
    min-height: 3.5rem;
    box-sizing: border-box;
    padding: 0.7rem 0;

    &:hover {
      background-color: var(--background-color);
    }

    label {
      cursor: pointer;
    }

    .media {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2.25rem;
      max-width: 30%;
      padding-left: 0.75rem;

      svg,
      i {
        width: 1.75rem;
        height: auto;
        max-height: 1.75rem;
        font-size: 1.75rem;
        color: var(--primary-color-branded);
      }

      img {
        width: 1.75rem;
        height: auto;
        max-height: 1.75rem;

        &.round {
          object-fit: cover;
          margin-right: 0.5rem;
          border-radius: 50%;
        }

        &.large {
          width: 4rem;
          max-height: 4rem;
          margin-right: 0.5rem;
          object-fit: cover;
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 0.75rem;

      .label {
        margin-bottom: 0.25rem;
        &:last-child {
          margin-bottom: 0;
        }
      }

      .descriptor {
        font-size: 0.75rem;
      }
    }

    & > * {
      &:last-child {
        padding-right: 0.75rem;
      }
    }
  }
}
