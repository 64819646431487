.heading {
  color: var(--secondary-color);
  margin: 0 0 1rem;

  &.branded {
    color: var(--secondary-color-branded);
  }
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.75rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.25rem;
}
