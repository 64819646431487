.container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  .uploadButton {
    color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    box-sizing: content-box;
    padding: 0 !important;
    width: 24px !important;

    &::-webkit-file-upload-button {
      visibility: hidden;
    }

    &::before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 1.3rem;
      content: "\f093";
      color: var(--primary-color-branded);
      display: inline-block;
      transition: 250ms ease-out;
    }

    &:hover {
      &::before {
        transform: scale(1.1);
      }
    }
  }

  img {
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: contain;

    @media only screen and (min-width: 768px) {
      object-fit: cover;

      &:hover {
        object-fit: contain;
      }
    }
  }

  .deleteButton {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    background: transparent;
    border: none;
    padding: 0.5rem;
    outline: none;
    font: inherit;
    cursor: pointer;
    color: var(--error-color);

    @media only screen and (min-width: 768px) {
      top: 0;
      right: 0;
    }
  }
}
