.container {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  min-height: 100px;
  overflow: hidden;
  background: var(--outline-color);
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

  &:last-child {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row-reverse;
  }

  .details {
    background-color: var(--surface-color);
    background-image: repeating-radial-gradient(
        circle at 0 0,
        transparent 0,
        var(--surface-color) 20px
      ),
      repeating-linear-gradient(#202124, lighten(#202124, 3%));
    color: var(--on-surface-color);
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1rem;
    box-sizing: border-box;

    @media only screen and (min-width: 768px) {
      padding: 1.5rem 1rem;
    }

    & > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
    }

    & > .time {
      font-size: 0.75rem;

      & > .icon {
        margin-right: 0.25rem;
      }
    }
  }

  .message {
    flex: 2.5;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 1rem;

    @media only screen and (min-width: 768px) {
      padding: 1.5rem 2rem;
    }

    .quote {
      margin-top: 0.5rem;
      border-top: 1px solid var(--outline-color);
      border-bottom: 1px solid var(--outline-color);
      padding: 1rem 0.5rem;
      line-height: 1;
    }

    & > span {
      max-width: 690px;
    }

    & > .files {
      border-top: 1px solid var(--outline-color);
      margin-top: 1rem;
      padding-top: 1rem;
      display: flex;
      flex-direction: column;

      & > .label {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1rem;
        margin-right: 0;
        white-space: nowrap;
      }

      & > .fileList {
        display: flex;
        flex-direction: column;

        a {
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: none;
          }
        }
      }
    }
  }
}
