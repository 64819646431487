.searchWrapper {
  margin-bottom: 0 !important;

  .searchContainer {
    margin-bottom: 0 !important;

    .searchField {
      border-radius: 0;
    }
  }
}

.tableContainer {
  border: none;
}

.loading {
  padding: 1rem 0;
}
