.splitScreen {
  display: flex;
  position: absolute;
  top: 0;
  left: -280px;
  width: calc(100vw + 280px);
  height: calc(100% - var(--header-height) - env(safe-area-inset-bottom));
  transition: left 350ms ease-out;
  padding: 0 env(safe-area-inset-right, 0) env(safe-area-inset-bottom, 0)
    env(safe-area-inset-left, 0);

  @media only screen and (min-width: 360px) {
    left: calc(var(--nav-width) * -1);
    width: calc(100vw + var(--nav-width));
  }

  @media only screen and (min-width: 768px) {
    top: unset;
    bottom: 0;
  }

  .nav {
    width: 280px;

    @media only screen and (min-width: 360px) {
      width: var(--nav-width);
      max-width: 100vw;
    }
  }

  .main {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    box-sizing: border-box;

    .navCloseTrigger {
      position: absolute;
      top: 0;
      right: 0;
      width: 0;
      height: 100%;
      background: #000;
      opacity: 0.6;
      cursor: pointer;
      z-index: 1;
      transition: width 350ms ease-out;
    }
  }

  &.withNav {
    left: 0;

    .main {
      .navCloseTrigger {
        width: 100%;
      }
    }
  }
}
