.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .row {
    flex: 1;
    display: contents;
    flex-direction: column;

    @media only screen and (min-width: 768px) {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .widget {
      display: flex;
      border-radius: 0.5rem;
      overflow: hidden;
      background: lighten(#3c4042, 1%);
      margin-bottom: 1rem;
      min-height: 180px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
        rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

      @media only screen and (min-width: 768px) {
        flex: 1;
        margin-bottom: 0;
        margin-right: 1rem;

        &:last-child {
          margin-right: 0;
        }
      }

      &.center {
        justify-content: center;
        align-items: center;
      }
    }
  }
}
