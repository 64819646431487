.container {
  position: fixed;
  z-index: 15;
  max-height: 60vh;
  overflow-x: hidden;
  overflow-y: auto;

  &.topLeft {
    top: calc(env(safe-area-inset-top, 0) + 1rem);
    left: calc(env(safe-area-inset-left, 0) + 1rem);
    @media only screen and (min-width: 768px) {
      top: calc(var(--header-height) + 1rem);
      left: calc(env(safe-area-inset-left, 0) + 2rem);
    }

    .notification {
      animation-name: slideRight;
    }
  }

  &.topRight {
    top: calc(env(safe-area-inset-top, 0) + 1rem);
    right: calc(env(safe-area-inset-right, 0) + 1rem);
    @media only screen and (min-width: 768px) {
      top: calc(var(--header-height) + 1rem);
      right: calc(env(safe-area-inset-right, 0) + 2rem);
    }

    .notification {
      animation-name: slideLeft;
    }
  }

  &.bottomLeft {
    left: calc(env(safe-area-inset-left, 0) + 1rem);
    bottom: calc(env(safe-area-inset-bottom, 0) + var(--header-height) + 1rem);

    @media only screen and (min-width: 768px) {
      bottom: calc(env(safe-area-inset-bottom, 0) + 3rem);
    }

    @media only screen and (min-width: 1024px) {
      left: calc(env(safe-area-inset-left, 0) + 2rem);
    }

    .notification {
      animation-name: slideRight;
    }
  }

  &.bottomRight {
    right: calc(env(safe-area-inset-right, 0) + 1rem);
    bottom: calc(env(safe-area-inset-bottom, 0) + var(--header-height) + 1rem);

    @media only screen and (min-width: 768px) {
      bottom: calc(env(safe-area-inset-bottom, 0) + 3rem);
    }

    @media only screen and (min-width: 1024px) {
      right: calc(env(safe-area-inset-right, 0) + 1rem);
    }

    .notification {
      animation-name: slideLeft;
    }
  }

  .notification {
    position: absolute;
    width: 500px;
    max-width: 80vw;
    box-sizing: border-box;
    padding: 1rem;
    background: var(--surface-color);
    border-radius: 2px;
    margin-bottom: 1rem;
    position: relative;
    display: flex;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    animation-iteration-count: 1;
    animation-play-state: paused;

    &:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.success {
      border-left: 5px solid #48d969;
      .icon {
        background-color: #48d969;
      }
    }
    &.confirm {
      border-left: 5px solid #48d969;
      .icon {
        background-color: #48d969;
      }
    }
    &.error {
      border-left: 5px solid var(--error-color);
      .icon {
        background-color: var(--error-color);
      }
    }
    &.info {
      border-left: 5px solid var(--primary-color);

      .icon {
        background-color: var(--primary-color);
      }
    }
    &.progress {
      border-left: 5px solid var(--primary-color);

      .icon {
        background-color: var(--primary-color);
      }
    }

    .iconContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-right: 1rem;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        color: #ffffff;

        svg {
          max-width: 0.7rem;
        }
      }
    }

    .body {
      flex: 1;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-right: 2rem;

      .titleContainer {
        margin-bottom: 1rem;
      }
    }

    .closeContainer {
      position: absolute;
      top: 0;
      right: 0;
      .close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        cursor: pointer;
      }
    }

    .progressContainer {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 5px;
      background-color: var(--primary-color-branded);
      animation-name: timeout;
      animation-iteration-count: 1;
      animation-play-state: running;
    }
  }
}

@keyframes timeout {
  from {
    width: 99%;
  }
  to {
    width: 0;
  }
}

@keyframes slideLeft {
  from {
    left: 0;
  }
  to {
    left: 100%;
  }
}

@keyframes slideRight {
  from {
    right: 0;
  }
  to {
    right: 100%;
  }
}
