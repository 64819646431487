.container {
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  .rowsContainer {
    &:first-child {
      border-bottom: 1px solid var(--outline-color);
    }

    @media only screen and (min-width: 768px) {
      &:first-child {
        border-bottom: none;
        border-right: 1px solid var(--outline-color);
      }
    }

    .headerRow {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      border-bottom: 1px solid var(--outline-color);
    }

    .itemsListing {
      height: 20vh;
      max-height: 20vh;
      overflow-y: auto;

      @media only screen and (min-width: 768px) {
        height: auto;
        max-height: 50vh;
      }

      .noItemsLabel {
        display: block;
        padding: 1.5rem 1rem;
      }
    }
  }

  .footerRow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-top: 1px solid var(--outline-color);
    &:last-child {
      border-top: 1px solid var(--outline-color);
    }

    @media only screen and (min-width: 768px) {
      height: 50px;

      &:last-child {
        border-bottom: none;
        border-left: 1px solid var(--outline-color);
      }
    }
  }
}
