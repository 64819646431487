.mainSidebar {
  width: 100%;
  height: 100%;
  padding: calc(env(safe-area-inset-top, 0) + 1rem)
    calc(env(safe-area-inset-left, 0) + 1rem) 1rem
    calc(env(safe-area-inset-right, 0) + 1rem);
  box-sizing: border-box;
  background: var(--surface-color);
  background-clip: padding-box;
  display: flex;
  flex-direction: column;
  border-right: 1px solid var(--outline-color);

  @media only screen and (min-width: 768px) {
    padding: 1rem calc(env(safe-area-inset-left, 0) + 1rem)
      calc(env(safe-area-inset-bottom, 0) + 1rem)
      calc(env(safe-area-inset-right, 0) + 1rem);
  }

  @media only screen and (min-width: 1024px) {
    padding: 1rem calc(env(safe-area-inset-left, 0) + 2rem)
      calc(env(safe-area-inset-bottom, 0) + 1rem)
      calc(env(safe-area-inset-right, 0) + 2rem);
  }

  .serviceLogoContainer {
    padding-top: 20px;
    width: 100%;
    margin: 0 auto 3rem;
    text-align: center;

    @media only screen and (max-width: 410px) {
      margin: 0 auto 2rem;
    }

    @media only screen and (max-height: 567px) and (max-width: 1024px) {
      display: none;
    }

    svg {
      width: auto;
      max-width: calc(var(--nav-width) - 4rem);
      max-height: calc(100vh / 4);
    }
  }

  .mainNav {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    & > ul {
      list-style: none;
      padding-inline-start: 0;

      @media only screen and (min-width: 415px) and (max-width: 1023px) {
        width: var(--nav-width);
        margin: 0 auto;
      }

      & > li {
        margin-bottom: 1.25rem;

        &.active {
          & > a {
            label {
              font-weight: bold;
            }

            &::after {
              font-family: "Font Awesome 5 Free";
              font-weight: 900;
              content: "\f054";
            }
          }
        }

        a {
          text-decoration: none;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        & > .subNav {
          list-style: none;
          margin: 0.5rem 0 0 0;
          padding: 0 0 0 0.75rem;

          & > li {
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }

  .subscriptionCentre {
    width: 100%;
    display: flex;
    flex-direction: column;

    .chipsSector {
      width: 100%;
      padding: 1rem;
      box-sizing: border-box;
      background-color: var(--background-color);
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      cursor: grab;

      .chipsStats {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;

        &:last-child {
          margin-bottom: 0;
        }

        .chipsSectorValue {
          color: var(--primary-color-branded);
          font-weight: 100;
          font-size: 2rem;
          letter-spacing: 3px;
        }
      }
    }

    @media only screen and (max-height: 567px) and (max-width: 1024px) {
      flex-direction: row;
      align-items: center;
      justify-content: center;

      .chipsSector {
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 0;
        margin-right: 1rem;
        margin-bottom: 0;
        height: 60px;

        .chipsStats {
          margin-bottom: 0;
          align-items: center;

          .chipsSectorValue {
            font-size: 1rem;
            font-weight: 400;
          }
        }
      }

      button {
        height: 60px;
      }
    }
  }
}
