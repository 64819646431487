.react-tabs {
  -webkit-tap-highlight-color: transparent;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 0;

  @media only screen and (min-width: 768px) {
    padding: 0 2rem;
  }

  &__tab-list {
    margin: 0;
    padding: 0;
    border-bottom: none;
    background-color: var(--surface-color);
    display: flex;
    flex: 0 0 50px;
  }

  &__tab {
    flex: 1;
    text-align: center;
    display: inline-block;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 1rem 0.5rem;
    cursor: pointer;
    font-weight: 200;
    font-size: 1rem;
    letter-spacing: 1.6px;
    user-select: none;
    text-transform: uppercase;

    &--selected {
      color: var(--primary-color-branded);
      font-weight: 400;
      background: var(--surface-color);
      border-bottom: 2px solid var(--primary-color-branded);
    }

    &--disabled {
      opacity: 0.6;
      cursor: default;
    }

    &:focus {
      box-shadow: 0 0 5px var(--primary-color-branded);
      outline: none;

      &:after {
        content: "";
        position: absolute;
        height: 5px;
        left: -4px;
        right: -4px;
        bottom: -5px;
        background: var(--primary-color-branded);
      }
    }
  }

  &__tab-panel {
    border: none;
    background-color: var(--background-color);
    box-sizing: border-box;
    display: none;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (min-width: 768px) {
      border: 1px solid var(--outline-color);
      border-top: none;
    }

    &--selected {
      display: block;
    }
  }
}
