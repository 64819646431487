.section {
  display: flex;
  flex-direction: column;

  &.screen {
    flex: 1;
    overflow-x: auto;
    padding: 1.5rem 1rem 1rem;

    @media only screen and (min-width: 1024px) {
      padding: 2.5rem 2rem 2rem;
    }
  }

  &.first {
    padding: 1.5rem 1rem 0.5rem;

    @media only screen and (min-width: 1024px) {
      padding: 2.5rem 2rem 1rem;
    }
  }

  &.stack {
    padding: 0 1rem 1rem;

    @media only screen and (min-width: 1024px) {
      padding: 0 2rem 2rem;
    }
  }

  &.form {
    width: 100%;
    max-width: 690px;
    margin: 1.5rem auto 0;

    @media only screen and (min-width: 1024px) {
      margin: 2.5rem auto 0;
    }
  }

  &.center {
    align-items: center;
    justify-content: center;
  }

  &.centerX {
    width: 100%;
    max-width: 690px;
    margin: 0 auto;
    text-align: left;
  }

  &.centerY {
    justify-content: center;
  }
}
