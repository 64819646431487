.btn {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  font-weight: 200;

  white-space: nowrap;
  padding: 1rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in-out;
  outline: none;

  label {
    font-weight: 400;
    cursor: pointer;
    pointer-events: none;
  }

  &:active {
    box-shadow: 0px 0px 3px 0px red;
  }

  &.rounded {
    height: 2.5rem;
    padding: 0 0.625rem;
    border-radius: 1.25rem;
    justify-content: center;
  }

  // ------------------ Types ------------------ //
  &.basic {
    background: none;
    margin: 0;
    padding: 0;
  }
  &.primary {
    background-color: var(--primary-color);
    color: var(--on-primary-color);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--on-primary-color);
    }

    label {
      color: var(--on-primary-color);
      &:before {
        background-color: var(--on-primary-color);
      }
    }
  }
  &.primaryBranded {
    background-color: var(--primary-color-branded);
    color: var(--on-primary-color-branded);
    border: 1px solid transparent;

    &:hover {
      border: 1px solid var(--on-primary-color-branded);
    }

    label {
      color: var(--on-primary-color-branded);
      &:before {
        background-color: var(--on-primary-color-branded);
      }
    }
  }
  &.secondary {
    background-color: var(--surface-color);
    color: var(--on-surface-color);
    border: 1px solid var(--secondary-color);

    label {
      color: var(--on-surface-color);
      font-weight: 400;

      &:before {
        background-color: var(--on-surface-color);
      }
    }
  }
  &.secondaryBranded {
    background-color: var(--surface-color);
    color: var(--on-surface-color);
    border: 1px solid var(--secondary-color-branded);

    label {
      color: var(--on-surface-color);

      &:before {
        background-color: var(--secondary-color-branded);
      }
    }

    &:hover {
      color: var(--secondary-color-branded);
    }
  }
}
