.mainContainer {
  flex: 1;
  background-color: var(--background-color);
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;

  .introContainer {
    position: relative;
    flex: 2;
    color: var(--on-primary-color);
    border-right: 1px solid var(--outline-color);
    align-items: flex-start;
    justify-content: center;
    display: none;
    overflow-x: hidden;
    overflow-y: scroll;

    @media only screen and (min-width: 1024px) {
      display: flex;
    }

    .introMask {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: url("../../../assets/img/auth.jpg");
      background-position: center center;
      background-size: cover;
      // filter: blur(2px);
    }

    .intro {
      margin-top: 2rem;
      z-index: 2;
      padding: 2rem 1rem;
      background-color: rgba(0, 0, 0, 0.3);

      h1 {
        color: var(--on-surface-color);
        font-size: 7rem;
        margin: 0;
      }

      h2 {
        color: var(--on-surface-color);
        font-weight: 100;
        margin: 0 0 1rem;
      }

      h4 {
        color: var(--on-surface-color);
        font-weight: 200;
        padding-left: 5px;
        margin: 0;
      }
    }
  }

  .contentContainer {
    display: flex;
    background-color: var(--surface-color);
    flex: 1;
    flex-direction: column;
    padding: 1rem 2rem;
    overflow-x: hidden;
    overflow-y: auto;

    @media only screen and (min-width: 1024px) {
      min-width: 450px;
    }
    .header {
      flex: 0 0 50px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footer {
      flex: 0 0 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
