.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-clip: padding-box;
  overflow-x: auto;
  background: lighten(#3c4042, 1%);

  @media only screen and (min-width: 768px) {
    border: 1px solid var(--outline-color);
  }

  &.editable {
    justify-content: space-between;
    overflow-x: hidden;
  }

  .editableArea {
    flex: 1;
    overflow: auto;
  }

  .table {
    width: 100%;
    background-color: var(--surface-color);

    @media only screen and (min-width: 768px) {
      display: table;
    }

    &.editable {
      position: relative;

      .head {
        .cell {
          border-right: 1px solid var(--outline-color);
          position: sticky;
          top: 0;
          background: var(--surface-color) !important;
          z-index: 1;

          &.masterCell {
            border-right: 1px solid var(--outline-color);
          }
        }
      }

      .slaveCells {
        .cell {
          background: darken(#3c4042, 1%);

          @media only screen and (min-width: 768px) {
            background: unset;
          }
        }

        .actionsCell {
          background: unset;
        }
      }
    }

    .head {
      display: block;

      @media only screen and (min-width: 768px) {
        display: table-header-group;
      }

      .cell {
        border-bottom: 1px solid var(--outline-color) !important;
        -webkit-user-select: none !important;
      }
    }

    .row {
      display: flex;
      flex-direction: column;

      @media only screen and (min-width: 768px) {
        display: table-row;
      }

      &:not(.clickableRow) {
        &:nth-child(2n) {
          background: #c0c0c01c;
        }
      }

      &.clickableRow {
        cursor: pointer;
        &:hover {
          background: lighten(#202124, 10%);
        }

        &.addMore {
          background: lighten(#202124, 15%);

          &:hover {
            background: darken(#202124, 5%);
            label {
              color: var(--primary-color-branded);
            }
          }
        }

        label {
          font-weight: bold;
          cursor: pointer;
        }
      }

      &.summaryRow {
        background-color: #000 !important;

        & .cell {
          font-weight: bold;

          @media only screen and (min-width: 768px) {
            min-width: 180px;
            border-right: 1px solid var(--outline-color);
            padding: 0;

            &:last-child {
              border-right: none;
            }

            &.actionsCell {
              padding: 0 1rem;
            }
          }

          &.cellGallery {
            height: auto;
          }
        }

      }

      .slaveCells {
        display: flex;
        flex-direction: column;

        @media only screen and (min-width: 768px) {
          display: contents;
          padding: 0;
        }

        .cell {
          padding: 0 1rem;
          justify-content: space-between;
          background: darken(#3c4042, 1%);

          @media only screen and (min-width: 768px) {
            text-align: center;
            background: unset;
          }

          &.actionsCell {
            background: unset;
          }
        }
      }

      .cell {
        display: flex;
        align-items: center;
        min-height: 3rem;
        overflow: hidden;
        white-space: nowrap;
        -webkit-user-select: text;

        @media only screen and (min-width: 768px) {
          display: table-cell;
          vertical-align: middle;
          height: 3rem;
          padding: 1rem;
          box-sizing: border-box;
        }

        &.masterCell {
          justify-content: space-between;
          padding: 0 1rem;
          border-bottom: 1px solid var(--outline-color);

          @media only screen and (min-width: 768px) {
            padding: 1rem;
            border: none;
          }
        }

        &.actionsCell {
          justify-content: center;
          border-top: 1px solid var(--outline-color);

          @media only screen and (min-width: 768px) {
            border: none;
          }
        }

        &.centered {
          justify-content: center;
          text-align: center;
        }

        .inputWrapper {
          width: 50%;
          position: relative;

          @media only screen and (min-width: 768px) {
            width: 100%;
          }

          &.currency {
            & > i {
              position: absolute;
              display: block;
              transform: translate(0, -50%);
              top: 50%;
              pointer-events: none;
              width: 2rem;
              text-align: center;
              font-style: normal;
            }

            & > .currencyVal {
              padding-left: 2rem;
            }
          }

          &.pictures {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            border-bottom: 1px solid var(--outline-color);

            @media only screen and (min-width: 768px) {
              border-bottom: none;
              justify-content: space-evenly;
            }

            span {
              display: inline-flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              padding: 0 10px;
              height: 44px;
              width: 44px;
              box-sizing: border-box;
              transition: 250ms ease-out;

              &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 1.3rem;
                content: "\f302";
                color: var(--secondary-color-branded);
                display: inline-block;
              }
              &:hover {
                transform: scale(1.2);
              }
            }

            input {
              color: transparent;
              cursor: pointer;
              padding: 0 10px;
              width: 44px;
              outline: none;
              box-sizing: border-box;
              transition: 250ms ease-out;
              border: none;

              &::-webkit-file-upload-button {
                visibility: hidden;
              }

              &::before {
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                font-size: 1.3rem;
                content: "\f093";
                color: var(--primary-color-branded);
                display: inline-block;
              }

              &:hover {
                transform: scale(1.2);
              }
            }
          }
        }

        &.cellGallery {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: unset;
          width: auto;
          height: 100px;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;

          @media only screen and (min-width: 768px) {
            width: 130px;
            padding: 3px;
          }

          & > * {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .row {
      &.editable {
        .cell {
          border: none;

          @media only screen and (min-width: 768px) {
            min-width: 180px;
            border-right: 1px solid var(--outline-color);
            padding: 0;

            &:last-child {
              border-right: none;
            }

            &.actionsCell {
              padding: 0 1rem;
            }
          }

          .fieldLabel {
            display: flex;
            align-items: center;
            width: 50%;
            height: 3rem;
          }

          input,
          select {
            box-sizing: border-box;
            background: none;
            border-radius: 0;
            width: 100%;
            padding: 1rem;
            color: var(--text-color);
            font-weight: 200;
            font-size: 16px;
            outline: none;
            letter-spacing: 1.6px;
            appearance: none;
            text-align: right;
            border: none;
            border-bottom: 1px solid var(--outline-color);

            &:focus {
              border-bottom: 1px dotted var(--primary-color-branded);
            }

            @media only screen and (min-width: 768px) {
              border: 1px dotted transparent;
              text-align: left;

              &:focus {
                border: 1px dotted var(--primary-color-branded);
              }
            }
          }

          input[type="number"] {
            text-align: right;
          }

          select {
            min-width: 100px;
            text-align-last: right;
          }

          &.cellGallery {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: unset;
            width: auto;
            height: 100px;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;

            @media only screen and (min-width: 768px) {
              width: 130px;
              padding: 3px;
            }

            & > * {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
