.container {
  .logo {
    animation: transition ease-in;
    animation-play-state: running;
  }
}

@keyframes transition {
  from {
    transform: scale(2);
  }
  to {
    transform: scale(1);
  }
}
