.header {
  flex: 0 0 var(--header-height);
  background-color: var(--surface-color);
  background-clip: padding-box;
  display: flex;
  justify-content: space-between;
  padding: env(safe-area-inset-top, 0) calc(1rem + env(safe-area-inset-left, 0))
    env(safe-area-inset-bottom, 0) calc(1rem + env(safe-area-inset-right, 0));
  border-top: 1px solid var(--outline-color);
  z-index: 2;

  @media only screen and (min-width: 768px) {
    border-top: none;
    padding-bottom: 0;
    border-bottom: 1px solid var(--outline-color);
  }

  @media only screen and (min-width: 1024px) {
    padding: env(safe-area-inset-top, 0)
      calc(2rem + env(safe-area-inset-left, 0)) 0
      calc(2rem + env(safe-area-inset-right, 0));
  }

  .navToggleContainer {
    height: var(--header-height);
    display: flex;
    align-items: center;

    > * {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .navToggle {
      display: block;
    }
  }

  .actionsContainer {
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > .profileTrigger {
      margin-right: 0.8rem;
      padding: 0;
      text-align: right;

      .profileTriggerWrapper {
        min-height: auto;
      }
    }

    .triggersContainer {
      display: flex;

      > button {
        margin-right: 0.8rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
