.separatorContainer {
  background-color: var(--surface-color);
  box-sizing: border-box;
  padding: 0.5rem 4px 0.25rem;

  .separator {
    width: 100%;
    height: 1px;
    background-color: var(--background-color);
    box-sizing: border-box;
  }
}
