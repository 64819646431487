@import "../../../assets/styles/button-types.scss";
@import "../../../assets/styles/animated-label.scss";

.link {
  color: var(--secondary-color);

  label {
    cursor: pointer;

    &:before {
      background-color: var(--secondary-color);
    }
  }

  &:hover {
    text-decoration: none;
  }

  &.branded {
    color: var(--secondary-color-branded);

    label {
      &:before {
        background-color: var(--secondary-color-branded);
      }
    }
  }
}
