a .label {
  cursor: pointer;
}

.labelContainer {
  & > * {
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }

  // &.withIcon {
  //   display: flex;
  //   align-items: center;
  // }
}

.label {
  font-weight: 200;
  font-size: 1rem;
  letter-spacing: 1.6px;
  user-select: none;

  &.uppercase {
    text-transform: uppercase;
  }
}

.small {
  font-size: 0.75rem;
}

.large {
  font-size: 1.25rem;
}
