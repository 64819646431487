.intro {
  margin-bottom: 0 !important;

  p {
    margin-bottom: 12px !important;
  }
}

.requiredNote {
  font-size: 0.875rem;
  line-height: 1.5;
}

.disclaimer {
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.error {
  font-size: 0.875rem;
  color: var(--error-color);
  line-height: 1.5;
  display: block;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
