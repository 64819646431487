.container {
  position: absolute;
  bottom: calc(100% - 5px);
  right: -5px;
  width: 360px;
  max-width: calc(100vw - 2rem);
  max-height: 75vh;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--background-color);
  z-index: 10;
  border-radius: 3px;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    top: calc(100% - 5px);
    bottom: initial;
  }

  .header {
    flex: 0 0 50px;
    background-color: var(--surface-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--outline-color);
  }

  .options {
    .actionItem {
      &:last-child {
        padding-bottom: 4px;
      }
    }
  }

  .body {
    padding: 1rem 2rem;
    box-sizing: border-box;
  }
}
