@import "../../../assets/styles/button-types.scss";
@import "../../../assets/styles/animated-label.scss";

.link,
.brandedLink {
  color: var(--secondary-color);
  display: inline-block;
  padding: 0;
  background: none;

  label {
    cursor: pointer;
    font-weight: 200;

    &:before {
      background-color: var(--secondary-color);
    }
  }

  &:hover {
    text-decoration: none;
  }
}

.brandedLink {
  color: var(--secondary-color-branded);

  label {
    &:before {
      background-color: var(--secondary-color-branded);
    }
  }
}

.fullWidth {
  width: 100%;
  justify-content: center;
}

.disabled {
  pointer-events: none;
  opacity: 0.6;
}
